<template>
  <json-viewer :value="jsonData" :expand-depth="999" :copyable="true" :theme="'json-viewer json-viewer--pt0 jv-light'">
    <template slot="copy">
      <button class="button button--mini" type="button">
        <span>Копировать</span>
      </button>
    </template>
  </json-viewer>
</template>

<script>
import JsonViewer from 'vue-json-viewer'

export default {
  name: 'Request1c',
  components: {
    JsonViewer
  },
  data() {
    return {
      jsonData: {},
    }
  },
  mounted() {
    this.$store.dispatch('orders/GET_DATA', this.$route.params.id)
      .then(response => {
        this.jsonData = JSON.parse(response.data.loaded_body)
      })
      .catch(error => {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: `Ошибка: ${ error.message }`
        })
      })
  }
}
</script>
